import React, { useEffect, useMemo, useState } from "react";
import {
  BillboardGraphics,
  Entity,
  PointGraphics,
  PolygonGraphics,
  Billboard,
} from "resium";
import * as Cesium from "cesium";
import { EntityGraphicsState } from "../MDPViewer";
import { ApiHvPylonResponse, HvPylon } from "../../../types/grid";
import { Features, Process } from "../../../types/media";

interface PylonGeoJsonComponent {
  selectedPylon: HvPylon | null;
  pylonList: ApiHvPylonResponse;
  selectedProcesses: Process[];
  setSelectedPylon: (pylon: HvPylon) => void;
}

export const PylonGeoJsonComponent = ({
  setSelectedPylon,
  selectedPylon,
  pylonList,
  selectedProcesses,
}: PylonGeoJsonComponent) => {
  const [data, setData] = useState<HvPylon[]>([]);

  useEffect(() => {
    if (selectedPylon) {
      setData([selectedPylon]);
      return;
    }

    if (pylonList?.data) {
      setData(pylonList.data);
    } else {
      setData([]);
    }
  }, [pylonList, selectedPylon]);

  const pylons = useMemo(() => {
    return data.map((marker) => {
      const pylon = marker.attributes;
      const pointCoords = Cesium.Cartesian3.fromDegreesArray(
        pylon?.geometry?.coordinates as number[],
      );
      const image = generateCounterImage(selectedProcesses.length) as string;
      return (
        <Entity
          key={marker.id}
          id={marker.id as string}
          position={pointCoords[0]}
          name={pylon?.name || ""}
          properties={{ type: "pylon_2d" }}
          onClick={() => {
            setSelectedPylon(marker);
          }}
          label={{
            fillColor: Cesium.Color.BLACK,
            // fillColor: {
            //   rgba: [255, 255, 255, 255],
            // },
            font: "12pt Lucida Console",
            // horizontalOrigin: "LEFT",
            pixelOffset: new Cesium.Cartesian2(0, -30),
            // style: "FILL",
            text: pylon?.name as string,
            showBackground: false,
            backgroundColor: Cesium.Color.WHITE,
          }}
        >
          <PointGraphics {...EntityGraphicsState.NORMAL_Red_Point_Graphics} />
          {selectedPylon && (
            <BillboardGraphics
              image={image}
              scale={1}
              color={new Cesium.Color(1.0, 1.0, 1.0, 0.8)}
              pixelOffset={new Cesium.Cartesian2(50, 20)}
            />
          )}
        </Entity>
      );
    });
  }, [data, selectedProcesses, setSelectedPylon, selectedPylon]);

  if (data && data?.length <= 0) return null;
  return pylons;
};

const generateCounterImage = (counter: number) => {
  // Set canvas size
  const size = 64;

  // Create canvas element
  let canvas: HTMLCanvasElement | null = document.createElement("canvas");
  canvas.width = size;
  canvas.height = size;
  const context = canvas.getContext("2d");
  if (!context) return null;

  // Draw circle
  const centerX = canvas.width / 2;
  const centerY = canvas.height / 2;
  const radius = size / 2 - 10; // Adjust the radius as needed

  context.shadowColor = "#b9b9b9";
  context.shadowBlur = 5;

  // border
  context.beginPath();
  context.arc(centerX, centerY, radius + 2, 0, 2 * Math.PI);
  context.fillStyle = "#000"; // White circle
  context.fill();

  context.shadowBlur = 0;

  context.beginPath();
  context.arc(centerX, centerY, radius, 0, 2 * Math.PI);
  context.fillStyle = "#808080"; // White circle
  context.fill();

  // Draw counter text
  context.font = "bold 18px Lucida Console";
  context.fillStyle = "#000000"; // Black text color
  context.textAlign = "center";
  context.textBaseline = "middle";
  context.fillText(counter.toString(), centerX, centerY + 2);

  // Convert canvas to data URI
  const dataURI = canvas.toDataURL();

  // Clean up
  canvas = null;

  return dataURI;
};

import React, {
  useState,
  useRef,
  useCallback,
  useMemo,
  useEffect,
} from "react";

import clsx from "clsx";
import { Viewer, ImageryLayer } from "resium";
import "cesium/Build/Cesium/Widgets/widgets.css";
import { Box } from "@mui/material";
import * as Cesium from "cesium";

import { calculateBoundingBox } from "../../util/coordinates";
import { useApi } from "../../hooks/useApi";
import { MetadataGeoJsonComponent } from "./GeoJsonComponent/MetadataGeoJsonComponent";
import { DatasetGeoJsonComponent } from "./GeoJsonComponent/DatasetGeoJsonComponent";
import { PylonGeoJsonComponent } from "./GeoJsonComponent/PylonGeoJsonComponent";
import { LocationGeoJsonComponent } from "./GeoJsonComponent/LocationGeoJsonComponent";
import { useGridApi } from "../../hooks/useGridApi";
import {
  RightSideControls,
  areaOfInterestLogic,
  getViewerViewArea,
} from "./Components/RightSideControls";
import { InputSearch } from "./Components/InputSearch";
import { Results } from "./Components/Results";
import { TopFilters } from "./Components/TopFilters";
import { ResultsWindow } from "./Components/ResultsWindow";
import { MapSwitcher } from "./Components/MapSwitcher";

import {
  ApiMetadataResponse,
  ApiSolutionsResponse,
  DroneImageDetail,
  Metadata,
  paths,
  Process,
  Solution,
} from "../../types/media";
import {
  ApiHvPylonResponse,
  HvPylon,
  paths as gridPaths,
} from "../../types/grid";
import getWebGLStub from "../../__mocks__/cesiumjs-webgl-stub";
import { isTestingWithJest } from "../../util/testing";
import { useStyles } from "./styles";
import dayjs, { Dayjs } from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isBetween from "dayjs/plugin/isBetween";

// extend dayjs
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);

// type
declare global {
  interface Window {
    CESIUM_BASE_URL: string;
  }
}

export enum SEARCH_TYPE {
  PYLON = "PYLON",
  DATASET = "DATASET",
}

export enum ZOOM_TYPE {
  NO_OFFSET = "NO_OFFSET",
  SINGLE_OFFSET = "SINGLE_OFFSET",
  DOUBLE_OFFSET = "DOUBLE_OFFSET",
}

export enum RIGHT_SIDE_SEARCH_BUTTON_ID {
  // dataset
  datasetAtPointSearch = "datasetAtPointSearch",
  datasetInView = "datasetInView",
  datasetViaAreaOfInterest = "datasetViaAreaOfInterest",
  // metadata
  metadataInView = "metadataInView",
  metadataViaAreaOfInterest = "metadataViaAreaOfInterest",
  // location based search
  locateMe = "locateMe",
  userLocationBasedSearch = "userLocationBasedSearch",
}

export type DateRange<T> = [T | null, T | null];

export interface SearchConfig {
  endpoint?: string;
  _resource?: string;
  searchTitleText?: string;
  focusOnResults?: boolean;
  next?: string;
}

// default data response data
Cesium.Camera.DEFAULT_VIEW_RECTANGLE = Cesium.Rectangle.fromDegrees(
  0,
  0,
  0,
  89,
);
Cesium.Camera.DEFAULT_VIEW_FACTOR = 1.5;

export const defaultData = {
  count: 0,
  next: null,
  previous: null,
  results: [],
};

type GlobExtended = Cesium.Globe & {
  _surface: { _tilesToRender: { _level: number }[] };
};

// set assets URL
window.CESIUM_BASE_URL = "/cesium";

export const EntityGraphicsState = {
  NORMAL_Red_Point_Graphics: {
    color: Cesium.Color.RED.withAlpha(0.7),
    pixelSize: 20,
    outlineColor: Cesium.Color.BLACK,
    outlineWidth: 2,
  },
  NORMAL_Point_Graphics: {
    color: Cesium.Color.CYAN.withAlpha(0.7),
    pixelSize: 20,
    outlineColor: Cesium.Color.BLACK,
    outlineWidth: 2,
  },
  HOVER_Point_Graphics: {
    color: Cesium.Color.BLUE,
    pixelSize: 21,
  },
  NORMAL_Ellipsoid_Graphics: {
    radii: new Cesium.Cartesian3(2.0, 2.0, 3.0),
    material: Cesium.Color.RED,
    // outline: true,
    // outlineColor: Cesium.Color.BLACK,
    // outlineWidth: 2,
  },
  HOVER_Ellipsoid_Graphics: {
    radii: new Cesium.Cartesian3(3.0, 3.0, 5.0),
    material: Cesium.Color.BLUE,
  },
  NORMAL_Polygon_graphics: {
    height: 0,
    material: Cesium.Color.RED.withAlpha(0.3),
    outlineColor: Cesium.Color.BLACK,
    outlineWidth: 1,
    outline: true,
  },
  HOVER_Polygon_graphics: {
    material: Cesium.Color.BLUE.withAlpha(0.6),
  },
};

// IT SEEMS THIS IS NOT FREE WE NEED TO PROVIDE TOKEN
// const defaultBaseLayer = Cesium.ImageryLayer.fromProviderAsync(
//   Cesium.ArcGisMapServerImageryProvider.fromBasemapType(
//     Cesium.ArcGisBaseMapType.SATELLITE,
//     { enablePickFeatures: false },
//   ),
//   {},
// );

let rollbackStyleLastHoverEntityFn = () => {};
const imageDetailsCache = {};

// process names: 'DSGVO_2021-06-25_HlO_Mit', 'DSGVO_Line1', 'DSGVO_Pole1', 'DSGVO_Pole2', 'DSGVO_Pole3_2', 'DSGVO_Pole3_1', 'DSGVO_Pole4_1_LightRoom_Error'
// pylon names: 'Lorem Hic', 'placeat', 'quas sit', 'culpa!', 'Lorem sit', 'magnam,', 'amet esse', 'dolor', 'nobis sit', 'magnam,', 'odit sit', 'odit'
export const MDPViewer = () => {
  const { classes } = useStyles();
  const { apiCall } = useApi();
  const { gridApiCall } = useGridApi();
  const [contextOptions, _] = useState(
    isTestingWithJest()
      ? {
          getWebGLStub,
        }
      : {},
  );

  // ref
  const isLoaded = useRef<boolean>(false);
  const viewerRef = useRef<Cesium.Viewer | null>(null); // created ref to Viewer
  const userSelectingRef = useRef<boolean>(false);

  // map state
  const [tileZoomLevel, setTimeZoomLevel] = useState(1);
  const [isBaseLayer, setIsBaseLayer] = useState<boolean>(true);
  const [mapProjection] = useState(new Cesium.WebMercatorProjection());
  // const [defaultBaseLayer] = useState(
  //   new Cesium.ImageryLayer(
  //     new Cesium.OpenStreetMapImageryProvider({
  //       url: "https://tile.openstreetmap.de/",
  //     }),
  //     {},
  //   ),
  // );

  const [topoTiles] = useState<Cesium.ImageryLayer>(
    new Cesium.ImageryLayer(
      new Cesium.UrlTemplateImageryProvider({
        url: `https://api.maptiler.com/maps/topo-v2/{z}/{x}/{y}@2x.png?key=${window.REACT_APP_MAPTILER_KEY}`,
        minimumLevel: 0,
        maximumLevel: 20,
        tileWidth: 512,
        tileHeight: 512,
        credit: new Cesium.Credit(
          `<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>`,
          true,
        ),
      }),
    ),
  );
  const [satelliteTiles] = useState<Cesium.ImageryLayer>(
    new Cesium.ImageryLayer(
      new Cesium.UrlTemplateImageryProvider({
        url: `https://api.maptiler.com/maps/21f1be22-17c0-463f-9969-4c1cdaaf45c5/{z}/{x}/{y}@2x.jpg?key=${window.REACT_APP_MAPTILER_KEY}`,
        minimumLevel: 0,
        maximumLevel: 20,
        tileWidth: 512,
        tileHeight: 512,
        credit: new Cesium.Credit(
          `<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>`,
          true,
        ),
      }),
    ),
  );

  // settings
  const [is3D, setIs3D] = useState<boolean>(false);
  const [hoverId, setHoverId] = useState<string | null>(null);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [hoverProcessId, setHoverProcessId] = useState<string | null>(null);
  const [myLocation, setMyLocation] = useState<GeoJSON.Point | null>(null);

  //state
  const [searchText, setSearchText] = useState<string>("");
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const [resultLoading, setResultLoading] = useState<boolean>(false);
  const [searchButtonLoading, setSearchButtonLoading] = useState("");
  const [searchHelperText, setSearchHelperText] = useState<string>("");
  const [searchType, setSearchType] = useState<SEARCH_TYPE>(SEARCH_TYPE.PYLON);
  const isPylonSearch = useMemo(
    () => searchType === SEARCH_TYPE.PYLON,
    [searchType],
  );
  const [searchButtonId, setSearchButtonId] = useState("");
  const [solutions, setSolutions] = useState<ApiSolutionsResponse>(
    {} as ApiSolutionsResponse,
  );
  const [fileProcessingLevels, setFileProcessingLevels] = useState<string[]>([
    "",
  ]);
  const [processProcessingLevels, setProcessProcessingLevels] = useState<
    string[]
  >([""]);
  const [selectedImageDetails, setSelectedImageDetails] =
    useState<DroneImageDetail | null>(null);

  // filter state
  const [dateRangeDataset, setDateRangeDataset] = React.useState<
    DateRange<Dayjs>
  >([null, null]);
  const [dateRangeMetadata, setDateRangeMetadata] = React.useState<
    DateRange<Dayjs>
  >([null, null]);
  const [selectedMediaDataType, setSelectedMediaDataType] = useState<string[]>(
    [],
  ); // DroneImage","SatelliteImage","PointCloud"
  const [selectedFileProcessingLevel, setSelectedFileProcessingLevel] =
    useState<string | null>(null);
  const [selectedProcessProcessingLevel, setSelectedProcessProcessingLevel] =
    useState<string | null>(null);
  const [clickedProcess, setClickedProcess] = useState<Process | null>(null);
  const [selectedProcesses, setSelectedProcesses] = useState<Process[]>([]);
  const [selectedSolutionProcess, setSelectedSolutionProcess] =
    useState<Solution | null>(null);
  const [selectedSolutionMetadata, setSelectedSolutionMetadata] =
    useState<Solution | null>(null);
  const [rightSideFilter, setRightSideFilter] = useState<
    Record<string, string>[]
  >([]);

  // pylons
  const [initialPylonList, setInitialPylonList] = useState<ApiHvPylonResponse>(
    {},
  );
  const [pylonRespData, setPylonRespData] = useState<ApiHvPylonResponse>({});
  const [selectedPylon, setSelectedPylon] = useState<HvPylon | null>(null);

  // results
  const [respData, setRespData] = useState<ApiMetadataResponse>({});

  useEffect(() => {
    if (!pylonRespData?.data) {
      setSelectedPylon(null);
    }
  }, [pylonRespData]);

  useEffect(() => {
    if (isPylonSearch && !selectedPylon) {
      resetSearch();
    }
  }, [isPylonSearch, selectedPylon]);

  const apiCallWithResetState = useCallback(
    <Path,>(args) => {
      resetStateData();
      return apiCall<Path>(args);
    },
    [apiCall],
  );

  const gridApiCallWithResetState = useCallback(
    <Path,>(args) => {
      resetStateData();
      return gridApiCall<Path>(args);
    },
    [apiCall],
  );

  const resetSearch = useCallback(() => {
    setSearchText("");

    resetFilters();
    resetSelectableFilters();
    resetResults();
    resetStateData();
    setMyLocation(null);
    // we do not reset zoom as of now
    // goHome();
  }, []);

  const resetSelectableFilters = () => {
    setSelectedFileProcessingLevel(null);
    setSelectedProcessProcessingLevel(null);
    setSelectedSolutionMetadata(null);
    setSelectedSolutionProcess(null);
    setSelectedMediaDataType([]);
  };

  const resetFilters = () => {
    setRightSideFilter([]);
    setSelectedProcesses([]);
    setSearchHelperText("");
  };

  const resetResults = () => {
    setRespData({} as ApiMetadataResponse);
    setPylonRespData({} as ApiHvPylonResponse);
    resetStateData();
  };

  const resetStateData = () => {
    // reset stuff initially
    setSearchHelperText("");
    setSelectedId(null);
    setHoverId(null);
  };

  const resetSearchBeforeRightSideSearch = () => {
    setSearchType(SEARCH_TYPE.DATASET);

    setSelectedSolutionMetadata(null);
    setSelectedSolutionProcess(null);
    setSelectedFileProcessingLevel(null);
    setSelectedProcessProcessingLevel(null);
    setClickedProcess(null);
    setSearchText("");
    resetFilters();
    resetResults();
  };

  useEffect(() => {
    // fetch initial data

    // pylons
    gridApiCall<gridPaths["/assets/hv-pylons/v1"]>(``).then((resp) => {
      setInitialPylonList(resp);
    });

    // solutions
    apiCall<paths["/api/v1/solutions"]>(`/solutions?limit=999`).then((resp) => {
      setSolutions(resp);
    });

    // processing levels
    apiCall<paths["/api/v1/enumerations/processingLevel"]>(
      `/enumerations/processingLevel`,
    ).then((processProcessingLevelsResp) => {
      setProcessProcessingLevels(
        (processProcessingLevelsResp?.results || [""]) as string[],
      );
    });

    // file processing levels
    apiCall<paths["/api/v1/enumerations/fileProcessingLevel"]>(
      "/enumerations/fileProcessingLevel",
    ).then((fileProcessingLevelsResp) => {
      setFileProcessingLevels(
        (fileProcessingLevelsResp?.results || [""]) as string[],
      );
    });
  }, []);

  useEffect(() => {
    const getImageData = async () => {
      if (selectedId) {
        if (!(selectedId in imageDetailsCache)) {
          setSelectedImageDetails(null);
          const imageData = await apiCall<paths["/api/v1/droneimagedetails"]>(
            `/droneimagedetails?metadataId=${selectedId}`,
          );
          if (!imageData?.results?.length) return;
          imageDetailsCache[selectedId] = imageData?.results[0];
          setSelectedImageDetails(imageDetailsCache[selectedId]);
        } else {
          setSelectedImageDetails(imageDetailsCache[selectedId]);
        }
      }
    };
    getImageData();
  }, [selectedId]);

  const pylonTypeSearch = useCallback(() => {
    if (clickedProcess) return;
    gridApiCallWithResetState<gridPaths["/assets/hv-pylons/v1"]>(
      `?filter=equals(name,'${searchText}')&limit=999`,
    )
      .then(async (res) => {
        if (!res?.data?.length) {
          setSearchHelperText("Nothing found? Switch to Dataset.");
          setSearchLoading(false);
          return;
        }

        setPylonRespData(res);
        setSelectedPylon(res.data[0]);
        setSearchLoading(false);
      })
      .catch((error) => {
        setSearchLoading(false);
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 404 ||
          error?.response?.status === 503
        ) {
          // on 404 error we set data to empty
          // so we can show error message about empty data
          // as api is limited to send 404 for id search
          setSearchHelperText("Network Error.");
        } else {
          setRespData({});
        }
      });
  }, [searchText, clickedProcess]);

  const processTypeSearch = useCallback(() => {
    // initial get id of process from the name
    apiCallWithResetState<paths["/api/v1/processes"]>(
      `/processes?name=${searchText}`,
    )
      .then(async (res) => {
        if (!res.results) {
          throw Error("Network Error.");
        }
        if (res.results?.length > 0) {
          setSelectedProcesses(res.results);
          zoomToEntities(ZOOM_TYPE.SINGLE_OFFSET);
        } else {
          // no process found
          setSelectedProcesses([]);
          setSearchHelperText("No processes found with given name.");
        }
        setSearchLoading(false);
      })
      .catch((error) => {
        setSearchLoading(false);
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 404 ||
          error?.response?.status === 503
        ) {
          // on 404 error we set data to empty
          // so we can show error message about empty data
          // as api is limited to send 404 for id search
          setSearchHelperText("Network Error.");
        } else {
          setRespData({} as ApiMetadataResponse);
        }
      });
  }, [searchText]);

  useEffect(() => {
    if (isLoaded.current) {
      processListSearch();
    }
  }, [selectedPylon, selectedProcessProcessingLevel, selectedSolutionProcess]);

  const processListSearch = useCallback(
    async (additionalFilters: Record<string, string>[] = []) => {
      setResultLoading(true);
      const filters: Record<string, string>[] = [];

      if (rightSideFilter.length > 0) {
        additionalFilters = rightSideFilter;
      }

      // for pylon search
      if (
        selectedPylon &&
        selectedPylon.attributes &&
        selectedPylon.attributes.geometry
      ) {
        const coOrd = selectedPylon.attributes.geometry.coordinates;
        if (coOrd) {
          const geom = `SRID=4326;POINT (${coOrd[0]} ${coOrd[1]})`;
          filters.push({ key: "geom", value: geom });
        }
      }

      if (additionalFilters.length > 0) {
        additionalFilters.forEach((searchParam) => {
          filters.push(searchParam);
        });
      }

      if (selectedSolutionProcess) {
        filters.push({
          key: "solutionId",
          value: selectedSolutionProcess.id ? selectedSolutionProcess.id : "",
        });
      }

      if (selectedProcessProcessingLevel) {
        filters.push({
          key: "processingLevel",
          value: selectedProcessProcessingLevel,
        });
      }

      const filterString = filters.reduce((acc, val) => {
        return acc + `&${val.key}=${val.value}`;
      }, "");

      let processesArr: Process[] = [];
      const processes = await apiCall<paths["/api/v1/processes"]>(
        `/processes?${filterString}`,
      );
      if (processes?.results && processes.results.length > 0) {
        processesArr = processes.results;
      }

      // no process for pylon
      setSearchHelperText(
        processesArr.length == 0 ? "Nothing found? Switch to Dataset." : "",
      );

      // zoom to entities
      zoomToEntities(
        processesArr.length == 0
          ? ZOOM_TYPE.NO_OFFSET
          : ZOOM_TYPE.SINGLE_OFFSET,
      );

      setSelectedProcesses(processesArr);
      setResultLoading(false);
    },
    [
      selectedPylon,
      selectedProcessProcessingLevel,
      selectedSolutionProcess,
      rightSideFilter,
    ],
  );

  // initial search
  const inputSearch = useCallback(async () => {
    if (searchText == "") {
      // return;
    }

    // reset all data with input search
    resetFilters();
    resetResults();

    setSearchLoading(true);

    if (isPylonSearch) {
      pylonTypeSearch();
    } else {
      processTypeSearch();
    }
  }, [isPylonSearch, pylonTypeSearch, processTypeSearch]);

  const createMetadataSearchCalls = useCallback(
    (additionalFilters) => {
      const searchCalls: Promise<ApiMetadataResponse>[] = [];
      const filters: Record<string, string>[] = [];

      if (clickedProcess) {
        filters.push({
          key: "processId",
          value: clickedProcess.id ? clickedProcess.id : "",
        });
      }

      if (selectedSolutionMetadata) {
        filters.push({
          key: "solutionId",
          value: selectedSolutionMetadata.id ? selectedSolutionMetadata.id : "",
        });
      }

      if (selectedFileProcessingLevel) {
        filters.push({
          key: "fileProcessingLevel",
          value: selectedFileProcessingLevel,
        });
      }

      if (additionalFilters.length > 0) {
        additionalFilters.forEach((searchParam) => {
          filters.push(searchParam);
        });
      }

      const filterString = filters.reduce((acc, val) => {
        return acc + `&${val.key}=${val.value}`;
      }, "");

      if (selectedMediaDataType.length > 0) {
        selectedMediaDataType.forEach((type) => {
          searchCalls.push(
            apiCallWithResetState(
              `/metadata?${filterString}&mediadataType=${type}&limit=999`,
            ),
          );
        });
      } else {
        searchCalls.push(
          apiCallWithResetState(`/metadata?${filterString}&limit=999`),
        );
      }
      return searchCalls;
    },
    [
      clickedProcess,
      selectedSolutionMetadata,
      selectedMediaDataType,
      selectedFileProcessingLevel,
    ],
  );

  // filter search
  const filterResults = useCallback(
    async (additionalFilters: Record<string, string>[] = []) => {
      if (!clickedProcess) return;
      // metadata extra filters
      setResultLoading(true);

      const searchCalls: Promise<ApiMetadataResponse>[] =
        createMetadataSearchCalls(additionalFilters);
      const results = await Promise.all(searchCalls);
      const metaDataResp = {
        count: 0,
        next: null,
        previous: null,
        results: [] as Metadata[],
      };

      results.forEach((res) => {
        if (!res || !res.count || !res.results) return;
        metaDataResp.count += res.count;
        metaDataResp.results = metaDataResp.results.concat(res.results);
      });

      if (metaDataResp?.results.length > 0) {
        setRespData(metaDataResp);
        zoomToEntities(ZOOM_TYPE.SINGLE_OFFSET);
      } else {
        setRespData({});
      }
      setResultLoading(false);
    },
    [
      selectedSolutionMetadata,
      selectedMediaDataType,
      selectedFileProcessingLevel,
      isPylonSearch,
      clickedProcess,
    ],
  );

  const hasResults = useMemo(() => {
    return (
      (respData?.results?.length as number) > 0 ||
      selectedProcesses.length > 0 ||
      rightSideFilter.length > 0 ||
      !!(
        isPylonSearch &&
        (selectedSolutionProcess ||
          selectedSolutionMetadata ||
          selectedProcessProcessingLevel ||
          selectedFileProcessingLevel)
      )
    );
  }, [
    respData,
    selectedProcesses,
    rightSideFilter,
    isPylonSearch,
    selectedSolutionMetadata,
    selectedSolutionProcess,
    selectedFileProcessingLevel,
    selectedProcessProcessingLevel,
  ]);

  useEffect(() => {
    if (hasResults) {
      filterResults();
    }
  }, [
    clickedProcess,
    selectedMediaDataType,
    selectedFileProcessingLevel,
    selectedSolutionMetadata,
    selectedSolutionProcess,
  ]);

  useEffect(() => {
    const makeRightSideSearch = async () => {
      try {
        await processListSearch();
        setSearchButtonLoading("");
      } catch (e) {
        console.log(e);
        setSearchButtonLoading("");
      }
    };
    if (rightSideFilter.length > 0) {
      makeRightSideSearch();
    }
  }, [rightSideFilter]);

  const selectedObject: Metadata | null = useMemo(() => {
    if (selectedId) {
      if (respData.results) {
        const result = respData.results.find((item) => item.id === selectedId);
        if (result) return result;
        return null;
      }
      return respData as Metadata;
    }
    return null;
  }, [selectedId, respData]);

  useEffect(() => {
    zoomToEntities(
      selectedObject ? ZOOM_TYPE.DOUBLE_OFFSET : ZOOM_TYPE.SINGLE_OFFSET,
    );
  }, [selectedObject]);

  useEffect(() => {
    zoomToEntities(ZOOM_TYPE.SINGLE_OFFSET);
  }, [clickedProcess]);

  const zoomToEntities = (offsetType: ZOOM_TYPE = ZOOM_TYPE.NO_OFFSET) => {
    const showZoomBox = false;
    const viewer = viewerRef.current;
    if (!viewer) return;
    setTimeout(() => {
      const mapEntities = viewer.entities.values.filter(
        (item) => !["selectorRect"].includes(item.id),
      );

      const bbox = calculateBoundingBox(
        selectedObject && selectedEntity ? [selectedEntity] : mapEntities,
      );
      if (!bbox) return;
      const west = bbox?.minLng;
      const south = bbox?.minLat;
      const east = bbox?.maxLng;
      const north = bbox?.maxLat;

      const initialOffset = 0.8 * (east - west); // 0.5;;
      let offset = initialOffset;
      let exOffset = west - east;

      const width = south - north;

      if (exOffset <= 0.0001) {
        // for single entities
        exOffset = -0.0012;
        offset = 0.001;
      }

      if (offset <= 0.0001) {
        // avoid error
        offset = 0.01;
        exOffset = -0.1;
      }

      let leftOffset = 0;
      switch (offsetType) {
        case ZOOM_TYPE.SINGLE_OFFSET:
          leftOffset = exOffset * 1.5;
          break;
        case ZOOM_TYPE.DOUBLE_OFFSET:
          leftOffset = exOffset * 2.5;
          break;
        default:
          break;
      }

      // long distance scenario
      if (width <= -0.01) {
        // avoid irregular zoom
        offset = initialOffset;
        leftOffset = leftOffset * 100;
      }

      const viewOffset = Cesium.Rectangle.fromDegrees(
        west - offset + leftOffset,
        south - offset,
        east + offset,
        north + offset,
      );

      viewer.entities.removeById("raw");
      showZoomBox &&
        viewer.entities.add({
          id: "raw",
          show: true,
          rectangle: {
            coordinates: Cesium.Rectangle.fromDegrees(
              west - offset,
              south - offset,
              east + offset,
              north + offset,
            ),
            fill: false,
            outline: true, // height must be set for outline to display
            outlineColor: Cesium.Color.BLACK.withAlpha(1),
          },
        });

      // avoid excessive zoom
      if (viewOffset.east - viewOffset.west < 0.000025) {
        const num = 0.00001;
        viewOffset.east = viewOffset.east + num;
        viewOffset.west = viewOffset.west - num;
      }

      viewer.entities.removeById("modified");
      showZoomBox &&
        viewer.entities.add({
          id: "modified",
          show: true,
          rectangle: {
            coordinates: viewOffset,
            fill: false,
            outline: true, // height must be set for outline to display
            outlineColor: Cesium.Color.RED.withAlpha(1),
          },
        });

      const options = {
        destination: viewOffset,
        orientation: {
          heading: 0.0, // east, default value is 0.0 (north)
          pitch: Cesium.Math.toRadians(-90), // default value (looking down)
          roll: 0.0, // default value
        },
        duration: 1,
      };

      viewer.camera.flyTo(options);
    }, 1);
  };

  const toggleSearchType = (argType) => {
    if (searchType === argType) return;
    setSearchType(argType);
    resetSearch();
  };

  const toggleSelectedMediaDataType = (argType) => {
    setSelectedMediaDataType((types) =>
      types.includes(argType)
        ? types.filter((t) => t !== argType)
        : types.concat([argType]),
    );
  };

  const goHome = (animation: boolean = true) => {
    if (!viewerRef.current) {
      return;
    }

    const zoomOption = {
      // destination: rectangle,
      destination: Cesium.Cartesian3.fromDegrees(
        10.322283,
        51.265259,
        1500000.0,
      ),
      duration: 1,
    };

    if (animation) {
      viewerRef.current.camera.flyTo(zoomOption);
    } else {
      viewerRef.current.camera.setView(zoomOption);
    }
  };

  // on hover
  useEffect(() => {
    const viewer = viewerRef.current;
    if (rollbackStyleLastHoverEntityFn) {
      try {
        rollbackStyleLastHoverEntityFn();
      } catch (e) {
        // entity can't be removed from the map
      }
    }
    const allHoverId = hoverId || hoverProcessId;
    if (allHoverId && viewer) {
      const entity = viewer.entities.getById(allHoverId);

      if (entity?.point) {
        entity.point.color = new Cesium.ConstantProperty(
          EntityGraphicsState.HOVER_Point_Graphics.color,
        );
        entity.point.pixelSize = new Cesium.ConstantProperty(
          EntityGraphicsState.HOVER_Point_Graphics.pixelSize,
        );
        rollbackStyleLastHoverEntityFn = () => {
          if (entity?.point) {
            entity.point.color = new Cesium.ConstantProperty(
              EntityGraphicsState.NORMAL_Point_Graphics.color,
            );
            entity.point.pixelSize = new Cesium.ConstantProperty(
              EntityGraphicsState.NORMAL_Point_Graphics.pixelSize,
            );
          }
        };
      } else if (entity?.ellipsoid) {
        entity.ellipsoid.material = new Cesium.ColorMaterialProperty(
          EntityGraphicsState.HOVER_Ellipsoid_Graphics.material,
        );
        entity.ellipsoid.radii = new Cesium.ConstantProperty(
          EntityGraphicsState.HOVER_Ellipsoid_Graphics.radii,
        );
        rollbackStyleLastHoverEntityFn = () => {
          if (entity?.ellipsoid) {
            entity.ellipsoid.material = new Cesium.ColorMaterialProperty(
              EntityGraphicsState.NORMAL_Ellipsoid_Graphics.material,
            );
            entity.ellipsoid.radii = new Cesium.ConstantProperty(
              EntityGraphicsState.NORMAL_Ellipsoid_Graphics.radii,
            );
          }
        };
      } else if (entity?.polygon) {
        entity.polygon.material = new Cesium.ColorMaterialProperty(
          EntityGraphicsState.HOVER_Polygon_graphics.material,
        );
        rollbackStyleLastHoverEntityFn = () => {
          if (entity?.polygon) {
            entity.polygon.material = new Cesium.ColorMaterialProperty(
              EntityGraphicsState.NORMAL_Polygon_graphics.material,
            );
          }
        };
      }
    }
  }, [hoverId, hoverProcessId]);

  const onload = (viewer: Cesium.Viewer, hasRef = false) => {
    // set ref
    viewerRef.current = viewer;
    isLoaded.current = true;

    if (hasRef) return;
    goHome(false);

    viewer.scene.imageryLayers.removeAll();
    viewer.scene.globe.baseColor = Cesium.Color.BLACK;

    // map events
    viewer.scene.globe.tileLoadProgressEvent.addEventListener(() => {
      const glob = viewer.scene.globe as GlobExtended;
      const tilesToRender = glob._surface._tilesToRender;
      const length = tilesToRender.length;
      if (length > 0) {
        setTimeZoomLevel(tilesToRender[0]?._level);
      }
    });

    // double click issue fixed
    viewer.screenSpaceEventHandler.removeInputAction(
      Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK,
    );

    // hover
    viewer.screenSpaceEventHandler.setInputAction((movement) => {
      try {
        const pickedFeature = viewer.scene.pick(movement.endPosition);
        viewer.canvas.style.cursor = pickedFeature?.primitive
          ? "pointer"
          : "initial";
      } catch (e) {
        // nothing for now
      }
    }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

    // event handler as resium component has issue onSelectedEntityChange
    viewer.selectedEntityChanged.addEventListener((selectedEntity) => {
      if (userSelectingRef.current) return;
      if (Cesium.defined(selectedEntity) && selectedEntity?.properties?.type) {
        if (
          ["metadata_3d", "metadata_2d"].includes(
            selectedEntity.properties.type.getValue(),
          )
        ) {
          if (Cesium.defined(selectedEntity.id)) {
            setSelectedId(selectedEntity.id);
          } else {
            // Unknown entity selected.
          }
        } else {
          setSelectedId(null);
        }
      } else {
        // we cant remove pylon as it will remove whole hierarchy
        // pylon -> dataset -> metadata
        // setSelectedPylon(null);
      }
    });

    // viewer.scene.morphTo2D(0);
    viewer.scene.screenSpaceCameraController.minimumZoomDistance = 192;
    viewer.scene.screenSpaceCameraController.maximumZoomDistance = 6378137 * 2;

    // THIS
    viewer.scene.screenSpaceCameraController.enableTilt = false;

    viewer.scene.screenSpaceCameraController.enableRotate = false;
    viewer.scene.screenSpaceCameraController.enableLook = false;

    viewer.scene.screenSpaceCameraController.inertiaZoom = 0;
  };

  useEffect(() => {
    if (viewerRef.current) {
      const viewer = viewerRef.current;
      if (is3D) {
        viewer.scene.screenSpaceCameraController.enableTilt = true;
      } else {
        viewer.scene.screenSpaceCameraController.enableTilt = false;
        const cartesian = Cesium.Cartographic.toCartesian(
          viewer.camera.positionCartographic,
        );
        viewer.camera.flyTo({
          destination: cartesian,
          orientation: {
            heading: 0.0,
            pitch: Cesium.Math.toRadians(-90),
            roll: 0.0,
          },
          duration: 1,
        });
      }
    }
  }, [is3D]);

  const selectedEntity = useMemo(() => {
    if (viewerRef.current && selectedId) {
      const viewer = viewerRef.current;
      const entity = viewer.entities.getById(selectedId);
      if (entity) {
        return entity;
      }
    }
    return undefined;
  }, [selectedId]);

  const zoomToLocation = () => {
    const viewer = viewerRef.current;
    if (!viewer) return;
    setTimeout(() => {
      const locationEntity = viewer.entities.getById("my_location");

      if (!locationEntity) return;
      const zoomOffsetResult = hasResults ? 0 : -1;
      const zoomOffsetType = selectedObject ? 1 : zoomOffsetResult;
      // const zoomOffsetType = 1
      const bbox = calculateBoundingBox([locationEntity]);
      if (!bbox) return;

      const west = bbox?.minLng;
      const south = bbox?.minLat;
      const east = bbox?.maxLng;
      const north = bbox?.maxLat;

      const offset = 0.0002;
      const exOffset = -0.0005;

      const otherOffset = zoomOffsetType == 0 ? exOffset : exOffset * 1.7;
      const leftOffset = zoomOffsetType == -1 ? 0 : otherOffset;
      const viewOffset = Cesium.Rectangle.fromDegrees(
        west - offset + leftOffset,
        south - offset,
        east + offset,
        north + offset,
      );

      // avoid excessive zoom
      if (viewOffset.east - viewOffset.west < 0.000025) {
        const num = 0.00001;
        viewOffset.east = viewOffset.east + num;
        viewOffset.west = viewOffset.west - num;
      }

      const options = {
        destination: viewOffset,
        orientation: {
          heading: 0.0, // east, default value is 0.0 (north)
          pitch: Cesium.Math.toRadians(-90), // default value (looking down)
          roll: 0.0, // default value
        },
        duration: 1,
      };

      viewer.camera.flyTo(options);
    }, 100);
  };

  const setMyLocationWithOption = useCallback(
    async ({
      location,
      withSearch = false,
    }: {
      location: GeoJSON.Point | null;
      withSearch?: boolean;
    }) => {
      const viewer = viewerRef.current;
      if (location == null) {
        setMyLocation(location);
        return;
      }
      if (viewer && location) {
        setMyLocation(location);
        if (withSearch) {
          setSearchButtonLoading(
            RIGHT_SIDE_SEARCH_BUTTON_ID.userLocationBasedSearch,
          );
          const coOrd = location.coordinates;
          const geom = `SRID=4326;POINT (${coOrd[0]} ${coOrd[1]})`;

          // reset all data with input search
          resetSearchBeforeRightSideSearch();

          const processRes = await apiCall<paths["/api/v1/processes"]>(
            `/processes?geom=${geom}`,
          );
          if (
            processRes &&
            processRes?.results &&
            processRes.results.length > 0
          ) {
            setSelectedProcesses(processRes.results);
          }
          setSearchButtonLoading("");
        }
        zoomToLocation();
      }
    },
    [hasResults, selectedEntity],
  );

  useEffect(() => {
    const viewer = viewerRef.current;
    if (!viewer) return;

    const screenSpaceEventHandler = new Cesium.ScreenSpaceEventHandler(
      viewer.scene.canvas,
    );
    // add selector
    let selectorRect: Cesium.Entity | null = viewer.entities.add({
      id: "selectorRect",
      show: false,
      isShowing: false,
      rectangle: {
        coordinates: new Cesium.Rectangle(),
        material: Cesium.Color.RED.withAlpha(0.3),
      },
    });

    const executeSwitch = async () => {
      switch (searchButtonId) {
        /*case RIGHT_SIDE_SEARCH_BUTTON_ID.processAtPointSearch: {
            screenSpaceEventHandler.setInputAction(
              (clickEvent: Cesium.ScreenSpaceEventHandler.PositionedEvent) => {
                const cartesian = viewer.camera.pickEllipsoid(
                  clickEvent.position,
                  viewer.scene.globe.ellipsoid,
                ) as Cesium.Cartesian3;

                // mouse cartographic
                const cartographic = Cesium.Cartographic.fromCartesian(
                  cartesian,
                  Cesium.Ellipsoid.WGS84,
                );

                const longitudeString = Cesium.Math.toDegrees(
                  cartographic.longitude,
                );
                const latitudeString = Cesium.Math.toDegrees(
                  cartographic.latitude,
                );
                const longLat = `${longitudeString} ${latitudeString}`;

                const geomPoint = `SRID=4326;POINT (${longLat})`;
                const endpoint = `/processes?geom=${geomPoint}&limit=999`;
                const title = `Your process result for search geom '${longLat}'`;
                loadData({
                  endpoint,
                  _resource: "processes",
                  searchTitleText: title,
                });
                setSearchButtonId("");
              },
              Cesium.ScreenSpaceEventType.LEFT_DOWN,
            );
            break;
          }*/
        case RIGHT_SIDE_SEARCH_BUTTON_ID.datasetInView:
        case RIGHT_SIDE_SEARCH_BUTTON_ID.metadataInView: {
          setSearchButtonId("");
          setSearchButtonLoading(searchButtonId);

          const geomPolygon = `SRID=4326;POLYGON ((${getViewerViewArea(viewer)}))`;
          const additionalFilters = [{ key: "geom", value: geomPolygon }];
          // reset all data with input search
          resetSearchBeforeRightSideSearch();
          setRightSideFilter(additionalFilters);
          break;
        }
        case RIGHT_SIDE_SEARCH_BUTTON_ID.datasetViaAreaOfInterest:
        case RIGHT_SIDE_SEARCH_BUTTON_ID.metadataViaAreaOfInterest: {
          const onSelectionComplete = async (polygonString: string) => {
            const geomPolygon = `SRID=4326;POLYGON ((${polygonString}))`;
            const additionalFilters = [{ key: "geom", value: geomPolygon }];

            setSearchButtonId("");
            setSearchButtonLoading(searchButtonId);

            // reset all data with input search
            resetSearchBeforeRightSideSearch();
            setRightSideFilter(additionalFilters);
          };
          await areaOfInterestLogic(
            viewer,
            screenSpaceEventHandler,
            selectorRect,
            userSelectingRef,
            onSelectionComplete,
          );
          break;
        }
        default: {
          break;
        }
      }
    };

    executeSwitch();

    return () => {
      screenSpaceEventHandler.destroy();
      if (selectorRect) {
        viewer.entities.remove(selectorRect);
        selectorRect = null;
      }
    };
  }, [apiCall, searchButtonId]);

  const resetDateRangeDataset = () => {
    if (selectedProcesses.length === 0) {
      setDateRangeDataset([null, null]);
    } else {
      let earliestDate = dayjs(dayjs(selectedProcesses[0].creationDate));
      selectedProcesses.forEach((process) => {
        if (dayjs(earliestDate).isAfter(dayjs(process.creationDate))) {
          earliestDate = dayjs(process.creationDate);
        }
      });
      setDateRangeDataset([
        earliestDate.isValid() ? earliestDate : dayjs(),
        dayjs(),
      ]);
    }
  };

  const resetDateRangeMetadata = () => {
    if (!respData.results) {
      setDateRangeMetadata([null, null]);
      return;
    }

    if (respData.results.length === 0) {
      setDateRangeMetadata([null, null]);
    } else {
      let earliestDate = dayjs(dayjs(respData.results[0].fileCreationDate));
      respData.results.forEach((file) => {
        if (dayjs(earliestDate).isAfter(dayjs(file.fileCreationDate))) {
          earliestDate = dayjs(file.fileCreationDate);
        }
      });
      setDateRangeMetadata([
        earliestDate.isValid() ? earliestDate : dayjs(),
        dayjs(),
      ]);
    }
  };

  useEffect(() => {
    resetDateRangeDataset();
  }, [selectedProcesses]);

  useEffect(() => {
    resetDateRangeMetadata();
  }, [respData]);

  const filteredMetadata: ApiMetadataResponse = useMemo(() => {
    const earliestDate =
      dateRangeMetadata[0] !== null
        ? dateRangeMetadata[0]
        : dayjs("1970-00-00");
    const latestDate =
      dateRangeMetadata[1] !== null ? dateRangeMetadata[1] : dayjs();
    const results = respData?.results
      ? respData.results.filter((metadata) =>
          dayjs(metadata.fileCreationDate).isBetween(
            earliestDate,
            latestDate,
            "day",
            "[]",
          ),
        )
      : [];
    return {
      ...respData,
      count: results.length,
      results,
    };
  }, [respData, dateRangeMetadata]);

  const filteredProcesses = useMemo(() => {
    const earliestDate =
      dateRangeDataset[0] !== null ? dateRangeDataset[0] : dayjs("1970-00-00");
    const latestDate =
      dateRangeDataset[1] !== null ? dateRangeDataset[1] : dayjs();
    return selectedProcesses.filter((process) =>
      dayjs(process.creationDate).isBetween(
        earliestDate,
        latestDate,
        "day",
        "[]",
      ),
    );
  }, [selectedProcesses, dateRangeDataset]);

  return (
    <Box className={classes.container}>
      <Box
        className={clsx(classes.sidebar, {
          [classes.sidebarActive]: hasResults,
        })}
        marginBottom={2}
      >
        <InputSearch
          setSearchText={setSearchText}
          searchText={searchText}
          inputSearch={inputSearch}
          searchLoading={searchLoading}
          isPylonSearch={isPylonSearch}
          toggleSearchType={toggleSearchType}
          resetSearch={resetSearch}
          searchHelperText={searchHelperText}
        />
        {hasResults && (
          <Results
            respData={filteredMetadata}
            selectedProcesses={filteredProcesses}
            isPylonSearch={isPylonSearch}
            selectedPylon={selectedPylon}
            clickedProcess={clickedProcess}
            setHoverProcessId={setHoverProcessId}
            setClickedProcess={setClickedProcess}
            selectedId={selectedId}
            setSelectedId={setSelectedId}
            setHoverId={setHoverId}
            resultLoading={resultLoading}
            fileProcessingLevels={fileProcessingLevels}
            processProcessingLevels={processProcessingLevels}
            selectedFileProcessingLevel={selectedFileProcessingLevel}
            selectedProcessProcessingLevel={selectedProcessProcessingLevel}
            setSelectedProcessProcessingLevel={
              setSelectedProcessProcessingLevel
            }
            setSelectedFileProcessingLevel={setSelectedFileProcessingLevel}
            solutions={solutions}
            selectedSolution={
              !clickedProcess
                ? selectedSolutionProcess
                : selectedSolutionMetadata
            }
            setSelectedSolution={
              !clickedProcess
                ? setSelectedSolutionProcess
                : setSelectedSolutionMetadata
            }
            dateRange={!clickedProcess ? dateRangeDataset : dateRangeMetadata}
            setDateRange={
              !clickedProcess ? setDateRangeDataset : setDateRangeMetadata
            }
            resetDateRange={
              !clickedProcess ? resetDateRangeDataset : resetDateRangeMetadata
            }
          />
        )}
      </Box>
      <TopFilters
        selectedMediaDataType={selectedMediaDataType}
        toggleSelectedMediaDataType={toggleSelectedMediaDataType}
      />
      <ResultsWindow
        selectedObject={selectedObject}
        selectedImageDetails={selectedImageDetails}
        setSelectedId={setSelectedId}
      />
      <MapSwitcher
        is3D={is3D}
        hasResults={hasResults}
        setIsBaseLayer={setIsBaseLayer}
        isBaseLayer={isBaseLayer}
        setIs3D={setIs3D}
      />
      <Box className={clsx({ [classes.selectPoint]: searchButtonId })}>
        <Viewer
          full
          ref={(r) => {
            if (r?.cesiumElement) {
              onload(r?.cesiumElement, !!viewerRef?.current);
            }
          }}
          contextOptions={contextOptions}
          sceneMode={1}
          animation={false}
          timeline={false}
          sceneModePicker={false}
          fullscreenButton={false}
          baseLayerPicker={false}
          maximumRenderTimeChange={Cesium.SceneMode.SCENE2D}
          infoBox={false}
          homeButton={false}
          geocoder={false}
          navigationHelpButton={false}
          mapProjection={mapProjection}
          selectedEntity={selectedEntity}
          // selectionIndicator={isPylonSearch ? false : true}
        >
          <ImageryLayer
            show={isBaseLayer}
            imageryProvider={topoTiles.imageryProvider}
          />
          <ImageryLayer
            show={!isBaseLayer}
            imageryProvider={satelliteTiles.imageryProvider}
          />
          <LocationGeoJsonComponent locationData={myLocation} />
          {isPylonSearch && !clickedProcess && (
            <PylonGeoJsonComponent
              pylonList={tileZoomLevel > 12 ? initialPylonList : {}}
              selectedPylon={selectedPylon}
              setSelectedPylon={setSelectedPylon}
              selectedProcesses={filteredProcesses}
            />
          )}
          {!clickedProcess && (
            <DatasetGeoJsonComponent
              selectedProcesses={selectedProcesses}
              setClickedProcess={setClickedProcess}
              hoverProcessId={hoverProcessId}
              isPylonSearch={isPylonSearch}
            />
          )}
          {clickedProcess && (
            <MetadataGeoJsonComponent
              clickedProcess={clickedProcess}
              isPylonSearch={isPylonSearch}
              mapData={filteredMetadata}
              is3D={is3D}
            />
          )}
          <RightSideControls
            myLocation={myLocation}
            searchButtonLoading={searchButtonLoading}
            setSearchButtonLoading={setSearchButtonLoading}
            setSearchButtonId={setSearchButtonId}
            searchButtonId={searchButtonId}
            setMyLocationWithOption={setMyLocationWithOption}
          />
        </Viewer>
      </Box>
    </Box>
  );
};
